.t {
  transform: none;
}
[os] h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
}
[os] p.astar {
  font-size: 0.625rem;
  line-height: 0.0625rem;
  line-height: 1;
  color: #9C9C9C;
}
[os] .holder {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
[os] .hero {
  position: relative;
  height: 300vh;
  padding-top: 3.75rem;
}
[os] .hero .title {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 3rem;
  padding-left: 0rem;
  text-align: center;
}
[os] .hero .title h2 {
  font-size: 1rem;
  line-height: 0.0625rem;
  line-height: 1;
  color: #2e2e2e;
}
[os] .hero .title h2 + p {
  font-size: 2.375rem;
  line-height: 2.875rem;
  font-weight: 700;
  margin-top: 0.625rem;
  color: #1e1e1e;
}
[os] .hero .holder {
  height: 100vh;
  text-align: center;
}
[os] .hero .kv {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
[os] .hero .kv video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
[os] .hero .bg {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-color: #000;
  width: 100%;
  height: 100%;
}
[os] .hero .overview {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
[os] .hero .overview p {
  color: #fff;
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
[os] .feature .holder {
  height: auto;
}
[os] .feature li {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 5rem;
  padding-left: 0rem;
}
[os] .feature li p:first-child {
  font-size: 1.25rem;
  line-height: 0.0625rem;
  line-height: 1;
  font-weight: 700;
}
[os] .feature li p + p {
  margin-top: 1rem;
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
}
[os] .feature li p + p + p {
  margin-top: 2.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #6E6E6E;
}
[os] .feature li .video {
  margin-top: 3rem;
}
[os] .feature li .video video {
  display: block;
  width: 100%;
  object-fit: contain;
  border-radius: 0.25rem;
  overflow: hidden;
}
[os] .feature li .video p {
  margin-top: 1rem;
  text-align: center;
}
[os] .structure {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 8rem;
  padding-left: 0rem;
  background-color: #EFEFEF;
}
[os] .structure .holder {
  height: auto;
}
[os] .structure video,
[os] .structure img {
  display: block;
  margin-top: 2.5rem;
  width: 19rem;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}
[os] .structure ol {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0rem;
  width: 16.375rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.75rem;
  line-height: 0.0625rem;
  line-height: 1;
  grid-row-gap: 10px;
}
[os] .structure ol li {
  font-weight: 700;
  width: 8.75rem;
}
[os] .structure ol li:nth-child(odd) {
  width: 6.375rem;
}
[os] .structure ol li:last-of-type {
  white-space: nowrap;
}
[os] .structure .holder > p {
  margin-top: 3rem;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  width: 18.9375rem;
  margin-left: auto;
  margin-right: auto;
}
[os] .structure .holder > p + p {
  margin-top: 3rem;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  width: 18.9375rem;
  margin-left: auto;
  margin-right: auto;
}
[os] .structure p:first-of-type {
  margin-top: 3rem;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  width: 18.9375rem;
  margin-left: auto;
  margin-right: auto;
}
[os] .structure p.astar {
  margin-top: 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  [os] .feature {
    height: 450vh;
  }
  [os] .feature ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0rem;
  }
  [os] .feature li {
    position: relative;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }
  [os] .feature li p {
    width: 19rem;
  }
  [os] .feature li .video {
    position: absolute;
    right: 0rem;
    top: 50%;
    left: auto;
    bottom: auto;
    transform: translateY(-50%);
    width: 19rem;
    height: auto;
    margin: 0;
  }
  [os] .feature li .video video {
    object-fit: cover;
    max-height: 86vh;
  }
  [os] .structure video,
  [os] .structure img {
    width: 26.75rem;
  }
}
@media (min-width: 1024px) {
  [os] h3 {
    font-size: 2.5rem;
    line-height: 0.0625rem;
    line-height: 1;
    text-align: center;
  }
  [os] p.astar {
    font-size: 0.875rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [os] .hero .title {
    padding-top: 5rem;
    padding-right: 0rem;
    padding-bottom: 5rem;
    padding-left: 0rem;
  }
  [os] .hero .title h2 {
    font-size: 2rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [os] .hero .title h2 + p {
    font-size: 4rem;
    line-height: 0.0625rem;
    line-height: 1;
    margin-top: 2rem;
  }
  [os] .hero .overview p {
    font-size: 5rem;
    line-height: 6rem;
    
  }
  [os] .feature li p {
    width: 24.5rem;
  }
  [os] .feature li p:first-child {
    font-size: 1.5rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [os] .feature li p + p {
    margin-top: 1.5rem;
    font-size: 3.5rem;
    line-height: 4.5rem;
    
  }
  [os] .feature li p + p + p {
    margin-top: 3.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    
  }
  [os] .feature li .video {
    width: 26.3125rem;
    height: auto;
  }
  [os] .feature li .video p {
    width: auto;
  }
  [os] .structure {
    height: 200vh;
    padding: 0;
  }
  [os] .structure .holder {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  [os] .structure video,
  [os] .structure img {
    width: 60rem;
    max-width: 70vh;
  }
  [os] .structure ol {
    display: none;
  }
  [os] .structure p:first-of-type {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    width: 45.375rem;
  }
}
@media (min-width: 1280px) {
  [os] {
    position: relative;
  }
  [os] .hero {
    padding-top: 5rem;
    height: 500vh;
  }
  [os] .hero .overview p {
    font-size: 6rem;
    line-height: 7rem;
    
  }
  [os] .feature li .video {
    width: 39.5rem;
    height: auto;
  }
  [os] .structure video {
    width: 60.5rem;
  }
}
@media (min-width: 1600px) {
  [os] .hero .overview p {
    font-size: 7rem;
    line-height: 8rem;
    
  }
  [os] .feature li .video {
    width: 43.625rem;
    height: auto;
  }
  [os] .structure video,
  [os] .structure img {
    width: 70rem;
  }
}
